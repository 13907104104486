import { useContext } from "react";
import {
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import * as yup from "yup";

import { AppointmentContext } from "../../providers/AppointmentProvider";
import { RepeatOrderContext } from "../../providers/RepeatOrderProvider";
import { useModal } from "../../hooks/modalsHooks";
import { useLazyGetPaymentIntentQuery } from "../../services/paymentService";
import {
    useAddOrderMutation,
    useAddRepeatOrderMutation,
    useLazyGetOrderPrescriptionFormularyQuery,
    useAddOrderPreferencesMutation
} from "../../services/orderService";
import useQuery from "../../hooks/useQuery";
import parseError from "../../utils/errorUtils";
import { getBase64 } from "../../utils/dataUtils";
import { getOrderName } from "../../utils/orderUtils";

import { Checkbox, Form, SubmitButton } from "../../components/forms";
import CardInput from "../../components/cardInput/CardInput";
import { useLoggerUtils } from "../../utils/loggerUtils";

const PaymentFormSchema = yup.object({
    name: yup.string().required("Your name on the card is incomplete."),
});

export function AppointmentCheckoutForm({
    initialValues,
    couponId,
    fullVoucher,
    doctor,
    orderData,
    prescriptionData,
    preferences,
    isDisable,
}) {
    const { appointmentData, setAppointmentData } =
        useContext(AppointmentContext);
    const { isRepeatOrder, parentOrderId, hasSixMonthsPassed } =
        useContext(RepeatOrderContext);
    const { showModal, hideModal } = useModal();
    const query = useQuery();
    const orderType = query.get("type");
    const isChangeOrder = query.get("is_change_order");
    const { id: orderId } = useParams();
    const { info } = useLoggerUtils();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [getPaymentIntent] = useLazyGetPaymentIntentQuery();
    const [addOrder] = useAddOrderMutation();
    const [addRepeatOrder] = useAddRepeatOrderMutation();
    const [getFormulary] = useLazyGetOrderPrescriptionFormularyQuery();
    const [addOrderPreferences] = useAddOrderPreferencesMutation();

    const handlePaymentDetailsProcess = async () => {
        const paymentIntent = await getPaymentIntent();

        const stripeResponse = await stripe.confirmCardSetup(
            paymentIntent?.data?.client_secret,
            {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                },
            }
        );

        if (stripeResponse?.error) {
            let stripeResponse_Obj = {
                code: stripeResponse?.error ? stripeResponse.error.code : "",
                decline_code: stripeResponse?.error ? stripeResponse.error.decline_code : "",
                doc_url: stripeResponse?.error ? stripeResponse.error.doc_url : "",
                message: stripeResponse?.error ? stripeResponse.error.message : "",
            }
            info("stripeResponse: " + JSON.stringify(stripeResponse_Obj));
            toast.error(stripeResponse.error.message || "Couldn't place order");
        }

        const patient = orderData?.patient;

        let patientDetails = null;

        if (patient) {
            patientDetails = {
                firstName: patient.firstName,
                lastName: patient.lastName,
                phone: patient.phone,
                email: patient.email,
                dateOfBirth: patient.dateOfBirth,
            };
        }

        const appointmentSavedPictures =
            appointmentData?.pictures?.length > 0
                ? await Promise.all(
                    appointmentData.pictures.map(async (doc) => {
                        const fileURL = await getBase64(doc.file);
                        return fileURL;
                    })
                )
                : [];

        let data = {
            paymentMethodID: stripeResponse.setupIntent.payment_method,
            deliveryType: "STANDARD",
            paymentType: "ONLINE",
            deviceType: "OTHER",
            orderNotes: [],
            info: {
                PRESENTING_COMPLAINT: appointmentData?.notes || "",
                ...(couponId
                    ? {
                        COUPON: couponId,
                    }
                    : {}),
            },
            ...(appointmentSavedPictures.length > 0 ? { appointmentSavedPictures } : {}),
        };

        if (patientDetails) {
            data = {
                ...data,
                orderNotes: [
                    {
                        type: "REPEAT_ORDER_PATIENT_DETAIL_CONFIRMATION",
                        questionAnswer: JSON.stringify(patientDetails),
                    },
                ],
            };
        }

        if (orderType === "REPEAT") {
            const { formulary } = prescriptionData?.medication[0];

            try {
                const formularyRes = await getFormulary({
                    formularyId: formulary.id,
                });

                const gpFormulary = orderData.orderNotes.find(
                    (note) => note.type === "GP_RECOMMENDATION"
                )?.formulary;

                let gpComment = "";

                if (gpFormulary) {
                    gpComment = JSON.parse(gpFormulary)?.comments;
                }

                if (formularyRes.error) {
                    // handle error
                } else {
                    // handle success

                    const prevPrescription = {
                        medicationType: "Medical Cannabis",
                        category: formularyRes.data.category,
                        route: formularyRes.data.administrationRoute,
                        manufacturer: formularyRes.data.manufacture,
                        brand: formularyRes.data.brandName,
                        strain: formularyRes.data.strain,
                        comments: gpComment || "",
                    };

                    let repeatOrderNotes = [
                        {
                            type: "REPEAT_ORDER_RECOMMENDATION",
                            formulary: JSON.stringify(prevPrescription),
                        },
                    ];

                    if (appointmentData?.prescriptionRejectReason) {
                        repeatOrderNotes.push({
                            type: "REPEAT_ORDER_PATIENT_PRESCRIPTION_CHANGE_REASON",
                            descriptionKey:
                                appointmentData.prescriptionRejectReason.reason,
                            description:
                                appointmentData.prescriptionRejectReason
                                    .tellUsMore,
                        });
                    }

                    if (appointmentData?.questionnaire) {
                        repeatOrderNotes.push({
                            type: "REPEAT_ORDER_QUESTIONNAIRE",
                            questionAnswer: JSON.stringify(
                                appointmentData?.questionnaire
                            ),
                        });
                    }

                    if (appointmentData?.pegForm) {
                        repeatOrderNotes.push(appointmentData?.pegForm);
                    }

                    if (appointmentData?.gadForm) {
                        repeatOrderNotes.push(appointmentData?.gadForm);
                    }

                    if (appointmentData?.sideEffectForm && appointmentData?.questionnaire.q_6.answer !== "") {
                        repeatOrderNotes.push(appointmentData?.sideEffectForm);
                    }

                    data = {
                        ...data,
                        name: getOrderName(orderData.name, orderType),
                        parentOrderItemId:
                            hasSixMonthsPassed || isRepeatOrder
                                ? Number(parentOrderId)
                                : Number(orderId),
                        orderNotes: [...data.orderNotes, ...repeatOrderNotes],
                        isRepeatOrder: true,
                    };

                    await handleAddRepeatOrder(data);
                }
            } catch (error) {
                info("handleAddRepeatOrder - catch: " + JSON.stringify(error));
                console.error(error);
            }
        } else {
            let newOrderNotes = [];

            if (appointmentData?.prescriptionRejectReason) {
                newOrderNotes.push({
                    type: "REPEAT_ORDER_PATIENT_PRESCRIPTION_CHANGE_REASON",
                    descriptionKey:
                        appointmentData.prescriptionRejectReason.reason,
                    description:
                        appointmentData.prescriptionRejectReason.tellUsMore,
                });
            }

            if (appointmentData?.questionnaire) {
                newOrderNotes.push({
                    type: "REPEAT_ORDER_QUESTIONNAIRE",
                    questionAnswer: JSON.stringify(
                        appointmentData?.questionnaire
                    ),
                });
            }

            if (appointmentData?.pegForm) {
                newOrderNotes.push(appointmentData?.pegForm);
            }

            if (appointmentData?.sideEffectForm && appointmentData?.questionnaire.q_6.answer !== "") {
                newOrderNotes.push(appointmentData?.sideEffectForm);
            }

            if (appointmentData?.gadForm) {
                newOrderNotes.push(appointmentData?.gadForm);
            }

            data = {
                ...data,
                name: getOrderName(appointmentData.name, orderType),
                type: appointmentData.type,
                appointment: {
                    id: Number(orderId),
                },
                patient: appointmentData.patient,
                orderNotes: [...data.orderNotes, ...newOrderNotes],
                isRepeatOrder: hasSixMonthsPassed
                    ? hasSixMonthsPassed
                    : isRepeatOrder,
            };

            if (parentOrderId || isRepeatOrder) {
                data = {
                    ...data,
                    parentOrderItemId:
                        hasSixMonthsPassed || isRepeatOrder
                            ? Number(parentOrderId)
                            : Number(orderId),
                };
            }

            await handleAddOrder(data);
        }
    };

    const handleAddOrder = async (orderData) => {
        try {
            let payload = orderData;
            if (orderData?.patient?.preferences?.TEMPORARY_APPOINTMENT) {
                payload = { ...orderData, source: 'CALL_BOOKING' };
            }
            const res = await addOrder({ type: "BOOKING", values: payload });

            if (res.error) {
                // handle error
                info("addOrder: " + JSON.stringify(res));
                toast.error(parseError(res.error, "Couldn't place order"));
            } else {
                //handle success
                // info("addOrder - success: " + JSON.stringify(res));
                setAppointmentData({});
                toast.success("Successfully placed order");
                navigate(`/user/dashboard`, { replace: true });
                showModal({
                    modalType: "SuccessModal",
                    modalProps: {
                        title: "Appointment successfully booked",
                        body: (
                            <>
                                <p className="fw-light mb-1">
                                    You’ve successfully booked your appointment
                                    on the
                                    <b>
                                        &nbsp;
                                        {dayjs(appointmentData.datetime).format(
                                            "MMMM Do, YYYY"
                                        )}
                                    </b>
                                    &nbsp;at&nbsp;
                                    <b>
                                        {dayjs(appointmentData.datetime).format(
                                            "hh:mm A"
                                        )}
                                    </b>
                                    &nbsp;with
                                    <b> {doctor?.displayName}</b>.
                                </p>
                                <p className="mb-0">
                                    Please check your spam folder if you haven’t
                                    received an email confirmation.
                                </p>
                            </>
                        ),
                        width: "46rem",
                        buttonText: "View my appointments",
                        onButtonClick: () => {
                            hideModal();
                        },
                    },
                });
            }
        } catch (e) {
            info("addOrder - catch: " + JSON.stringify(e));
            console.error(e);
        }
    };

    const handleAddRepeatOrder = async (data) => {
        try {
            const res = await addRepeatOrder({
                type: "BOOKING",
                values: data,
            });
            if (res.error) {
                // handle error
                info("handleAddRepeatOrder - error: " + JSON.stringify(res));
                toast.error(parseError(res.error, "Couldn't place order"));
            } else {
                //handle success
                if (isChangeOrder) {
                    try {
                        const data = {
                            orderItemId: res?.data?.message,
                            patientId: orderData?.patient?.id.toString(),
                            preferences: preferences && preferences?.map(pref => ({
                                sku: pref?.sku,
                                priority: pref?.priority?.toString(),
                                name: pref?.name,
                                imageURL: pref?.imageURL
                            }))
                        };

                        await handleChangeOrder(data);
                    } catch (error) {
                        info("handleAddRepeatOrder - catch: " + JSON.stringify(error));
                        console.error(error);
                    }
                }
                setAppointmentData({});
                // info("handleAddRepeatOrder - success: " + JSON.stringify(res));
                toast.success("Successfully placed order");
                navigate(`/user/dashboard`, { replace: true });
            }
        } catch (e) {
            info("handleAddRepeatOrder - catch: " + JSON.stringify(e));
            console.error(e);
        }
    };

    const handleChangeOrder = async (orderData) => {
        try {
            const res = await addOrderPreferences({
                values: orderData,
            });
            if (res.error) {
                // handle error
                info("handleAddRepeatOrder - error: " + JSON.stringify(res));
                toast.error(parseError(res.error, "Couldn't place order"));
            } else {
                //handle success
                setAppointmentData({});
            }
        } catch (e) {
            info("handleAddRepeatOrder - catch: " + JSON.stringify(e));
            console.error(e);
        }
    };

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlePaymentDetailsProcess}
            validationSchema={PaymentFormSchema}
            enableReinitialize
        >
            <CardInput disabled={fullVoucher} />
            {!fullVoucher && (
                <AppointmentCheckoutFormFooter orderType={orderType} isDisable={isDisable} />
            )}
        </Form>
    );
}

export function AppointmentCheckoutFormFooter({ isDisable }) {
    const { values } = useFormikContext();

    return (
        <>
            <div className="mt-4">
                <Checkbox
                    label="I am making this appointment for myself and not on behalf of another person, including children under the age of 18."
                    name="identity_consent"
                    className="check-lg"
                    labelClass="ms-3 text-primary"
                />
            </div>
            <div className="mt-4">
                <Checkbox
                    label={
                        <p className="mb-0 fw text-primary">
                            I have read and understood the&nbsp;
                            <NavLink
                                className="fw-bold text-primary text-decoration-underline"
                                to="/terms-condition"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms and Conditions
                            </NavLink>
                            &nbsp;prior to booking a consultation
                        </p>
                    }
                    tooltip="More Details"
                    name="terms_and_conditions"
                    className="check-lg"
                    labelClass="ms-3 text-primary"
                />
            </div>
            <div className="row mx-0 mt-6 mb-4">
                <SubmitButton
                    disabled={
                        !values.terms_and_conditions || !values.identity_consent || isDisable
                    }
                >
                    Confirm Payment
                </SubmitButton>
            </div>
        </>
    );
}

export default AppointmentCheckoutForm;
