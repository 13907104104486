import React, { createContext, useMemo } from "react";

import { useGetOrdersQuery, useGetOrderQuery } from "../services/orderService";
import {
    getIsOrderPast14Days,
    getIsOrderPast6Months,
} from "../utils/orderUtils";
import { useGetMyProfileQuery } from "../services/userService";
import { useAuth } from "../utils/authUtils";

export const RepeatOrderContext = createContext({
    isRepeatOrder: false,
    parentOrderId: null,
    availableToReorder: false,
    canOrder: true,
    hasSixMonthsPassed: false,
    isLoading: false,
});

export default function RepeatOrderProvider({ children }) {
    const auth = useAuth();

    const { data: ordersData, isLoading: ordersDataLoading } =
        useGetOrdersQuery(
            {},
            {
                skip: !auth.isLogged,
                refetchOnMountOrArgChange: true,
            }
        );
    const { data: profileData, isLoading: profileDataLoading } =
        useGetMyProfileQuery({}, { skip: !auth.isLogged });
    const { data: orderData, isLoading: orderDataLoading } = useGetOrderQuery(
        {
            appointmentId:
                profileData?.settings.LAST_VIDEO_CONSULTATION_ORDER_ID,
        },
        {
            skip:
                profileDataLoading ||
                !profileData?.settings.LAST_VIDEO_CONSULTATION_ORDER_ID,
            refetchOnMountOrArgChange: true,
        }
    );

    const isRepeatOrder = useMemo(() => {
        if (profileData) {
            return profileData.settings.INITIAL_CONSULTATION_DONE === "true";
        }

        return false;
    }, [profileData]);

    const parentOrderId = useMemo(() => {
        if (ordersData?.content.length > 0) {
            const eligibleOrders = ordersData?.content.filter(
                (order) => order.eligibleToReorder
            );

            if (eligibleOrders?.length > 0) {
                const latestOrderDate = Math.max(
                    ...eligibleOrders.map(
                        (e) => new Date(e.appointment?.datetime || e?.creationDate)
                    )
                );
                const latestOrderId = eligibleOrders.find(
                    (order) =>
                        new Date(order.appointment?.datetime || order?.creationDate).getTime() ===
                        latestOrderDate
                )?.id;

                return latestOrderId;
            }

            return null;
        }

        return null;
    }, [ordersData]);

    const hasSixMonthsPassed = useMemo(() => {
        if (orderData) {
            return getIsOrderPast6Months(orderData.appointment.datetime);
        }

        return false;
    }, [orderData]);

    const availableToReorder = useMemo(() => {
        if (ordersData?.content.length > 0 && !hasSixMonthsPassed) {
            const eligibleOrder = ordersData.content.find((order) => order.eligibleToReorder);
            if (eligibleOrder) {
                const dateToCheck = eligibleOrder.deliveryDate === null
                    ? eligibleOrder.updateDate
                    : eligibleOrder.deliveryDate;

                return !!getIsOrderPast14Days(dateToCheck);
            }
        }
        return false;
    }, [hasSixMonthsPassed, ordersData]);

    const canOrder = useMemo(() => {
        if (profileData) {
            return profileData.settings.IS_ONGOING_ORDER !== "true";
        }

        return false;
    }, [profileData]);

    const isLoading =
        ordersDataLoading || profileDataLoading || orderDataLoading;

    return (
        <RepeatOrderContext.Provider
            value={{
                isRepeatOrder,
                parentOrderId,
                availableToReorder,
                canOrder,
                hasSixMonthsPassed,
                isLoading,
            }}
        >
            {children}
        </RepeatOrderContext.Provider>
    );
}
