/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { useGetBillingInfoQuery } from "../../services/paymentService";
import { useLazyGetDoctorQuery } from "../../services/dataService";
import {
    useAddOrderMutation,
    useAddRepeatOrderMutation,
    useGetOrderPrescriptionQuery,
    useGetOrderQuery,
    useLazyGetOrderPrescriptionFormularyQuery,
    useLazyGetVoucherValidityQuery,
    useGetAccessTokenQuery,
    useLazyGetPreferencesQuery,
    useAddOrderPreferencesMutation
} from "../../services/orderService";
import { AppointmentContext } from "../../providers/AppointmentProvider";
import { RepeatOrderContext } from "../../providers/RepeatOrderProvider";
import { useModal } from "../../hooks/modalsHooks";
import useQuery from "../../hooks/useQuery";
import parseError from "../../utils/errorUtils";
import { getBase64 } from "../../utils/dataUtils";
import { getOrderName } from "../../utils/orderUtils";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

import {
    AppointmentCheckoutForm,
    AppointmentCheckoutFormFooter,
} from "./AppointmentCheckoutForm";
import GoBack from "../../components/GoBack";
import Collapsible from "../../components/collapsible/Collapsible";
import Avatar from "../../components/avatar/Avatar";
import Price from "../../components/price/Price";
import { Form, SubmitButton, TextField } from "../../components/forms";
import PreferenceSummary from "../../components/preferenceSummary/PreferenceSummary";
import "./appointment-page.scss";
import { useLoggerUtils } from "../../utils/loggerUtils";

const fonts = [
    {
        cssSrc: "https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600&display=swap",
        family: "Titillium Web",
    },
];

function AppointmentPaymentsPage() {
    const { appointmentData, setAppointmentData } =
        useContext(AppointmentContext);
    const { isRepeatOrder, parentOrderId, hasSixMonthsPassed } =
        useContext(RepeatOrderContext);
    const { showModal, hideModal } = useModal();
    const query = useQuery();
    const orderType = query.get("type");
    const { id: orderId } = useParams();

    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        name: "",
        terms_and_conditions: false,
    });
    const [couponId, setCouponId] = useState("");
    const [discount, setDiscount] = useState(0);
    const [doctorData, setDoctorData] = useState({});

    const [getDoctor] = useLazyGetDoctorQuery();
    const [getVoucherValidity] = useLazyGetVoucherValidityQuery();
    const [addOrder] = useAddOrderMutation();
    const [addRepeatOrder] = useAddRepeatOrderMutation();
    const { data: billingInfo } = useGetBillingInfoQuery();
    const { data: orderData } = useGetOrderQuery(
        { appointmentId: orderType === "REPEAT" ? orderId : parentOrderId },
        {
            skip: !(orderType === "REPEAT" ? orderId : parentOrderId),
            refetchOnMountOrArgChange: true,
        }
    );
    const { data: prescriptionData } = useGetOrderPrescriptionQuery(
        { orderId: orderType === "REPEAT" ? orderId : parentOrderId },
        {
            skip:
                !(orderType === "REPEAT" ? orderId : parentOrderId) ||
                !orderType,
            refetchOnMountOrArgChange: true,
        }
    );
    const [getFormulary] = useLazyGetOrderPrescriptionFormularyQuery();
    const isChangeOrder = query.get("is_change_order");
    const accessToken = localStorage.getItem('AccessToken' || '');
    // Lazy query for preferences
    const [triggerGetPreferences, { data: preferences, isLoading }] = useLazyGetPreferencesQuery();
    const [addOrderPreferences] = useAddOrderPreferencesMutation();
    const { info } = useLoggerUtils();

    // Automatically fetch the token for get preferences
    const { data: tokenData } = useGetAccessTokenQuery(
        // skip this api call if it's not change order
        {}, { skip: !isChangeOrder }
    );

    // Fetch preferences only after the first API call is successful
    useEffect(() => {
        if (tokenData && isChangeOrder) {
            triggerGetPreferences({ preferenceToken: tokenData.data, accessToken });
        }
    }, [tokenData, triggerGetPreferences, isChangeOrder]);

    const handleGetDoctor = async () => {
        if (appointmentData?.doctorId) {
            try {
                const doctorRes = await getDoctor({
                    doctorId: appointmentData.doctorId,
                });
                if (doctorRes.error) {
                    // handle error
                } else {
                    setDoctorData(doctorRes.data);
                }
            } catch (error) { }
        }
    };

    const handleCheckVoucherValidity = async (values) => {
        try {
            const res = await getVoucherValidity({ voucherId: values.voucher });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't submit voucher"));
                setCouponId(null);
            } else {
                // handle success
                if (res.data.valid) {
                    setCouponId(values.voucher);
                    const tempAppointmentPrice =
                        orderType === "REPEAT"
                            ? billingInfo?.repeatOrderCharge
                            : isRepeatOrder
                                ? billingInfo?.repeatAppointmentPrice
                                : billingInfo?.appointmentPrice;

                    const finalValue =
                        tempAppointmentPrice * res.data.percentage;

                    setDiscount(finalValue);
                    toast.success("Voucher added successfully");
                } else {
                    setCouponId(null);
                    toast.error(parseError(res.error, "The discount code provided is not applicable or valid for use."));
                }
            }
        } catch (error) {
            setCouponId(null);
            console.error(error);
        }
    };

    const handlePaymentDetailsProcess = async () => {
        const patient = orderData?.patient;

        let patientDetails = null;

        if (patient) {
            patientDetails = {
                firstName: patient.firstName,
                lastName: patient.lastName,
                phone: patient.phone,
                email: patient.email,
                dateOfBirth: patient.dateOfBirth,
            };
        }

        const appointmentSavedPictures =
            appointmentData?.pictures?.length > 0
                ? await Promise.all(
                    appointmentData.pictures.map(async (doc) => {
                        const fileURL = await getBase64(doc.file);
                        return fileURL;
                    })
                )
                : [];

        let data = {
            orderNotes: [],
            deliveryType: "STANDARD",
            paymentType: "VOUCHER",
            deviceType: "OTHER",
            info: {
                PRESENTING_COMPLAINT: appointmentData?.notes || "",
                COUPON: couponId,
            },
            ...(appointmentSavedPictures.length > 0 ? { appointmentSavedPictures } : {}),
        };

        if (patientDetails) {
            data = {
                ...data,
                orderNotes: [
                    {
                        type: "REPEAT_ORDER_PATIENT_DETAIL_CONFIRMATION",
                        questionAnswer: JSON.stringify(patientDetails),
                    },
                ],
            };
        }

        if (orderType === "REPEAT") {
            const { formulary } = prescriptionData?.medication[0];

            try {
                const formularyRes = await getFormulary({
                    formularyId: formulary.id,
                });

                const gpFormulary = orderData.orderNotes.find(
                    (note) => note.type === "GP_RECOMMENDATION"
                )?.formulary;

                let gpComment = "";

                if (gpFormulary) {
                    gpComment = JSON.parse(gpFormulary)?.comments;
                }

                if (formularyRes.error) {
                    // handle error
                } else {
                    // handle success
                    const prevPrescription = {
                        medicationType: "Medical Cannabis",
                        category: formularyRes.data.category,
                        route: formularyRes.data.administrationRoute,
                        manufacturer: formularyRes.data.manufacture,
                        brand: formularyRes.data.brandName,
                        strain: formularyRes.data.strain,
                        comments: gpComment || "",
                    };

                    let repeatOrderNotes = [
                        {
                            type: "REPEAT_ORDER_RECOMMENDATION",
                            formulary: JSON.stringify(prevPrescription),
                        },
                    ];

                    if (appointmentData?.prescriptionRejectReason) {
                        repeatOrderNotes.push({
                            type: "REPEAT_ORDER_PATIENT_PRESCRIPTION_CHANGE_REASON",
                            descriptionKey:
                                appointmentData.prescriptionRejectReason.reason,
                            description:
                                appointmentData.prescriptionRejectReason
                                    .tellUsMore,
                        });
                    }

                    if (appointmentData?.questionnaire) {
                        repeatOrderNotes.push({
                            type: "REPEAT_ORDER_QUESTIONNAIRE",
                            questionAnswer: JSON.stringify(
                                appointmentData?.questionnaire
                            ),
                        });
                    }

                    if (appointmentData?.pegForm) {
                        repeatOrderNotes.push(appointmentData?.pegForm);
                    }

                    if (appointmentData?.gadForm) {
                        repeatOrderNotes.push(appointmentData?.gadForm);
                    }

                    if (appointmentData?.sideEffectForm && appointmentData?.questionnaire.q_6.answer !== "") {
                        repeatOrderNotes.push(appointmentData?.sideEffectForm);
                    }

                    data = {
                        ...data,
                        name: getOrderName(orderData.name, orderType),
                        parentOrderItemId:
                            hasSixMonthsPassed || isRepeatOrder
                                ? Number(parentOrderId)
                                : Number(orderId),
                        isRepeatOrder: true,
                        orderNotes: [...data.orderNotes, ...repeatOrderNotes],
                    };

                    await handleAddRepeatOrder(data);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            let newOrderNotes = [];

            if (appointmentData?.prescriptionRejectReason) {
                newOrderNotes.push({
                    type: "REPEAT_ORDER_PATIENT_PRESCRIPTION_CHANGE_REASON",
                    descriptionKey:
                        appointmentData.prescriptionRejectReason.reason,
                    description:
                        appointmentData.prescriptionRejectReason.tellUsMore,
                });
            }

            if (appointmentData?.questionnaire) {
                newOrderNotes.push({
                    type: "REPEAT_ORDER_QUESTIONNAIRE",
                    questionAnswer: JSON.stringify(
                        appointmentData?.questionnaire
                    ),
                });
            }

            if (appointmentData?.pegForm) {
                newOrderNotes.push(appointmentData?.pegForm);
            }

            if (appointmentData?.gadForm) {
                newOrderNotes.push(appointmentData?.gadForm);
            }

            if (appointmentData?.sideEffectForm && appointmentData?.questionnaire.q_6.answer !== "") {
                newOrderNotes.push(appointmentData?.sideEffectForm);
            }

            data = {
                ...data,
                name: getOrderName(appointmentData.name, orderType),
                type: appointmentData.type,
                appointment: {
                    id: Number(orderId),
                },
                patient: appointmentData.patient,
                orderNotes: [...data.orderNotes, ...newOrderNotes],
                isRepeatOrder: hasSixMonthsPassed
                    ? hasSixMonthsPassed
                    : isRepeatOrder,
            };

            if (parentOrderId || isRepeatOrder) {
                data = {
                    ...data,
                    parentOrderItemId:
                        hasSixMonthsPassed || isRepeatOrder
                            ? Number(parentOrderId)
                            : Number(orderId),
                };
            }

            await handleAddOrder(data);
        }
    };

    const handleAddOrder = async (orderData) => {
        try {
            let payload = orderData;
            if (orderData?.patient?.preferences?.TEMPORARY_APPOINTMENT) {
                payload = { ...orderData, source: 'CALL_BOOKING' };
            }
            const res = await addOrder({ type: "BOOKING", values: payload });

            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't place order"));
            } else {
                //handle success
                setAppointmentData({});
                navigate(`/user/dashboard`, { replace: true });
                showModal({
                    modalType: "SuccessModal",
                    modalProps: {
                        title: "Appointment successfully booked",
                        body: (
                            <>
                                <p className="fw-light mb-1">
                                    You’ve successfully booked your appointment
                                    on the
                                    <b>
                                        &nbsp;
                                        {dayjs(appointmentData.datetime).format(
                                            "MMMM Do, YYYY"
                                        )}
                                    </b>
                                    &nbsp;at&nbsp;
                                    <b>
                                        {dayjs(appointmentData.datetime).format(
                                            "hh:mm A"
                                        )}
                                    </b>
                                    &nbsp;with
                                    <b> {doctorData?.displayName}</b>.
                                </p>
                                <p className="mb-0">
                                    Please check your spam folder if you haven’t
                                    received an email confirmation.
                                </p>
                            </>
                        ),
                        width: "46rem",
                        buttonText: "View my appointments",
                        onButtonClick: () => {
                            hideModal();
                        },
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleAddRepeatOrder = async (data) => {
        try {
            const res = await addRepeatOrder({
                type: "BOOKING",
                values: data,
            });
            if (res.error) {
                // handle error
                toast.error(parseError(res.error, "Couldn't place order"));
            } else {
                //handle success
                if (isChangeOrder) {
                    try {
                        const data = {
                            orderItemId: res?.data?.message,
                            patientId: orderData?.patient?.id.toString(),
                            preferences: preferences[0]?.preferences?.map(pref => ({
                                sku: pref?.sku,
                                priority: pref?.priority?.toString(),
                                name: pref?.name,
                                imageURL: pref?.imageURL
                            }))
                        };

                        await handleChangeOrder(data);
                    } catch (error) {
                        info("handleAddRepeatOrder - catch: " + JSON.stringify(error));
                        console.error(error);
                    }
                }
                setAppointmentData({});
                toast.success("Successfully placed order");
                navigate(`/user/dashboard`, { replace: true });
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        setInitialValues((prevData) => ({
            ...prevData,
            name:
                orderType === "REPEAT"
                    ? orderData?.patient?.displayName
                    : appointmentData?.patient?.displayName,
        }));

        handleGetDoctor();
    }, [appointmentData]);

    const stripePromise = useMemo(async () => {
        if (billingInfo) {
            return await loadStripe(billingInfo.publicKey);
        }

        return null;
    }, [billingInfo]);

    const appointmentPrice = useMemo(() => {
        const tempAppointmentPrice =
            orderType === "REPEAT"
                ? billingInfo?.repeatOrderCharge
                : isRepeatOrder
                    ? billingInfo?.repeatAppointmentPrice
                    : billingInfo?.appointmentPrice;

        if (discount > 0) {
            return tempAppointmentPrice - discount;
        }

        return tempAppointmentPrice;
    }, [billingInfo, discount, isRepeatOrder]);

    const handleChangeOrder = async (orderData) => {
        try {
            const res = await addOrderPreferences({
                values: orderData,
            });
            if (res.error) {
                info("handleAddRepeatOrder - error: " + JSON.stringify(res));
                toast.error(parseError(res.error, "Couldn't place order"));
            } else {
                setAppointmentData({});
            }
        } catch (e) {
            info("handleAddRepeatOrder - catch: " + JSON.stringify(e));
            console.error(e);
        }
    };

    return (
        <Elements stripe={stripePromise} options={{ fonts }}>
            <div className="row mx-0 h-100">
                <div className="col-md-6 pt-5 position-relative bg-light-gray">
                    <div className="row mx-0 mb-0 mb-md-8">
                        <div className="col-12 col-lg-11 col-xl-9 col-xxl-8 mx-auto pb-5 pb-md-0 mb-0 mb-md-8">
                            <GoBack />
                            <h3 className="text-center fw-bold text-brown-black mt-4">
                                Payment details
                            </h3>
                            <p className="mt-4 text-medium-gray fs-6 text-center">
                                {orderType === "REPEAT"
                                    ? "This is a pre-authorisation payment form. Your card will not be charged until your prescription is approved."
                                    : "Please provide your card details to book your appointment."}
                            </p>
                            <div className="mt-3 text-center">
                                <img
                                    src="/images/vectors/visa.svg"
                                    alt="visa"
                                />
                                <img
                                    className="ms-2"
                                    src="/images/vectors/mastercard.svg"
                                    alt="mastercard"
                                />
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                <AppointmentCheckoutForm
                                    initialValues={initialValues}
                                    couponId={couponId}
                                    fullVoucher={appointmentPrice === 0}
                                    doctor={doctorData}
                                    orderData={orderData}
                                    prescriptionData={prescriptionData}
                                    preferences={preferences && preferences[0]?.preferences}
                                    isDisable={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 pt-5 px-2 px-lg-6 mb-5 bg-white">
                    <div className="row mx-0 mb-5 mb-md-0 pb-md-0">
                        <div className="col-12 col-lg-12 col-xl-11 col-xxl-10 py-4 px-4 pb-4 pb-md-3 mb-0 mb-md-5 bg-gray-card border-custom rounded">
                            {orderType !== "REPEAT" && (
                                <div className="mb-5">
                                    <Collapsible title="Consultation Summary">
                                        <div className="text-primary fs-6 fw-bold">
                                            Consultation details
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-4">
                                            <div className="d-flex align-items-sm-center">
                                                <div
                                                    className="d-none d-sm-block"
                                                    style={{
                                                        width: 56,
                                                        height: 56,
                                                    }}
                                                >
                                                    <Avatar
                                                        src={
                                                            doctorData?.settings
                                                                ?.DOCTOR_AVATAR ||
                                                            "/images/samples/doctor-thumb.png"
                                                        }
                                                    />
                                                </div>

                                                <div className="ms-0 ms-sm-4">
                                                    <div className="primary-title fw-bold fs-5">
                                                        {
                                                            doctorData?.displayName
                                                        }
                                                    </div>
                                                    {appointmentData?.datetime && (
                                                        <div className="secondary-title text-dark-3 fw-light">
                                                            {dayjs(
                                                                appointmentData.datetime
                                                            ).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                            &nbsp; at&nbsp;
                                                            {dayjs(
                                                                appointmentData.datetime
                                                            ).format("hh:mma")}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {appointmentData?.notes && (
                                            <>
                                                <hr />
                                                <div className="text-primary fs-6 fw-bold">
                                                    Consultation notes
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <div className="text-dark-4 pe-2">
                                                        {appointmentData.notes}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Collapsible>
                                </div>
                            )}
                            <Collapsible title="Payment Summary" expanded>
                                <div>
                                    <div className="d-flex mb-4 align-items-center fw-bold justify-content-between text-primary fs-6">
                                        <div>Discount code</div>
                                    </div>
                                    <div className="text-medium-gray mb-2">
                                        If you have a discount code please enter
                                        it here
                                    </div>
                                    <div className="mb-5">
                                        <Form
                                            initialValues={{ voucher: "" }}
                                            onSubmit={
                                                handleCheckVoucherValidity
                                            }
                                        >
                                            <div className="input-group flex-nowrap">
                                                <TextField
                                                    name="voucher"
                                                    placeholder="Discount code"
                                                    className="rounded-0 rounded-start"
                                                    disabled={
                                                        appointmentPrice === 0
                                                    }
                                                />
                                                <SubmitButton
                                                    variant="primary fw-bold py-2 rounded-0 rounded-end"
                                                    disabledLoading
                                                    fullWidth={false}
                                                    disabled={
                                                        appointmentPrice === 0
                                                    }
                                                >
                                                    APPLY
                                                </SubmitButton>
                                            </div>
                                        </Form>
                                        {appointmentPrice === 0 && (
                                            <Form
                                                initialValues={{
                                                    terms_and_conditions: "",
                                                }}
                                                onSubmit={
                                                    handlePaymentDetailsProcess
                                                }
                                            >
                                                <AppointmentCheckoutFormFooter
                                                    orderType={orderType}
                                                />
                                            </Form>
                                        )}
                                    </div>
                                    <div className="d-flex mb-3 align-items-center justify-content-between text-medium-gray fs-6">
                                        <div>
                                            {
                                                orderType === "REPEAT"
                                                    ? "Repeat prescription fee"
                                                    : "Consultation fee"
                                            }
                                        </div>
                                        <div>
                                            <Price
                                                amount={appointmentPrice}
                                                currency="GBP"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="d-flex align-items-center fw-bold justify-content-between text-primary fs-5">
                                        <div>Total</div>
                                        <div>
                                            <Price
                                                amount={appointmentPrice}
                                                currency="GBP"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Collapsible>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="mt-3 mb-4 d-flex justify-content-center">
                            <LoadingSpinner />
                        </div>
                    )}
                    {preferences && !isLoading && (
                        <PreferenceSummary preferences={preferences[0]?.preferences} />
                    )}
                    <div className="row mx-0 mb-12 mb-md-0 pb-12 pb-md-0">
                        <div className="col-12 col-lg-12 col-xl-11 col-xxl-10 py-4 px-4 pb-5 pb-md-0 mb-0 mb-md-12 
                        bg-gray-card border-custom rounded">
                            <h6 className="d-flex flex-row fw-bold gap-1 text-primary mb-2">
                                <img
                                    src="/images/vectors/danger-primary.svg"
                                    className="me-2 my-auto"
                                    alt=""
                                />
                                Disclaimer
                            </h6>
                            <p className="text-dark-4 mb-4">
                                Your indicated preferences will be reviewed by the clinican and a prescription will be
                                issued considering your preferances and stock availability at our partner pharmacies.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Elements>
    );
}

export default AppointmentPaymentsPage;
