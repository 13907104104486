import React, { useContext, useState } from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

import { useAuth } from "../../utils/authUtils";
import { RepeatOrderContext } from "../../providers/RepeatOrderProvider";

import Button from "../button/Button";
import DropDown from "../dropDown/DropDown";
import SideDrawer from "../sideDrawer/SideDrawer";
import OutsideAlerter from "../OutsideAlerter";
import Collapsible from "../collapsible/Collapsible";
import Tooltip from "../tooltip/Tooltip";

import { TREAT_IT_URL } from "../../constants";

import "./nav-bar.scss";

const dropDownOptions = [
    { label: "Profile", value: "user/dashboard" },
    { label: "Signout", value: "signout" },
];

export default function Navbar({ profileData }) {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();
    const { isRepeatOrder, canOrder } = useContext(RepeatOrderContext);

    const [showProfileDrawer, setShowProfileDrawer] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    // Check if the current route is 'user/dashboard'
    const isDashboardRoute = location.pathname === '/user/dashboard';


    const handleRouteToLogin = () => {
        navigate("/auth/signin");
    };

    const handleSignout = () => {
        navigate("/signout");
    };

    const handleRouteToProfile = () => {
        navigate("/user/dashboard");
        setShowProfileDrawer(false);
    };

    const handleClickNotifications = () => {};

    const handleBookAppointment = () => {
        if (isRepeatOrder) {
            navigate(`/reorder/confirm`);
        } else {
            navigate("/appointment");
        }
        setShowProfileDrawer(false);
    };

    const handleSelectOption = (option) => {
        const route = option.toLowerCase();

        if (route === "profile") {
            handleRouteToProfile();
            return;
        }

        navigate(`/${route}`);
    };

    const handleRouteToWebsite = () => {
        window.location.href = TREAT_IT_URL;
    };

    const appointmentButtonText = isRepeatOrder
        ? "Book a repeat consultation"
        : "Book an appointment";

    return (
        <nav className="custom-nav bg-white d-flex justify-content-between align-items-center py-3 px-3 px-lg-5 position-fixed w-100">
            <div className="me-auto">
                <div className="d-flex align-items-center">
                    {/* <Button
                        variant=" p-0 border-0"
                        fullWidth={false}
                        onClick={() => setIsDrawerOpen(true)}
                    >
                        <img
                            src="/images/vectors/hamburger.svg"
                            className="me-5 d-block d-md-none"
                            alt=""
                        />
                    </Button> */}
                    <Button
                        variant=" p-0 border-0"
                        fullWidth={false}
                        onClick={handleRouteToWebsite}
                    >
                        <img className="logo" src="/images/logo.png" alt="" />
                    </Button>
                </div>
            </div>

            <div className="navbar-right justify-content-end align-items-center ms-auto">
                {/* <div className="links justify-content-center mx-auto">
                        <NavLink className="fw-bold fs-6" to="/">
                            Home
                        </NavLink>
                        <div className="d-flex align-items-center">
                            <NavLink className="fw-bold fs-6" to="/">
                            Conditions
                            </NavLink>
                            <div className="ms-2 top-nav-collapse" />
                        </div>
                        <NavLink className="fw-bold fs-6" to="/">
                            <nobr>About us</nobr>
                        </NavLink>
                        <NavLink className="fw-bold fs-6" to="/">
                            FAQ
                        </NavLink>
                    </div> */}
                {auth.isLogged ? (
                    <>
                        {isDashboardRoute && (
                            <Tooltip
                                message="There is already an active order in progress"
                                position="bottom"
                                hidden={canOrder}
                            >
                                <Button
                                    variant="primary btn-sm book-appointment-btn fw-bold px-2"
                                    onClick={handleBookAppointment}
                                    disabled={!canOrder}
                                    fullWidth={false}
                                >
                                    {appointmentButtonText}
                                </Button>
                            </Tooltip>
                        )}

                        <Button
                            variant=" p-0 border-0"
                            onClick={handleClickNotifications}
                        >
                            <img src="/images/vectors/bell-filled.svg" alt="" />
                        </Button>
                        <DropDown
                            searchable={false}
                            multiSelect={false}
                            dropDownOnly
                            options={dropDownOptions}
                            onSelect={handleSelectOption}
                        >
                            <Button
                                variant=" d-flex p-0 border-0 w-max"
                                fullWidth={false}
                            >
                                <p className="text-primary me-2 mb-0 fw-bold fs-6">
                                    {profileData?.firstName}
                                </p>
                                <img src="/images/vectors/user.svg" alt="" />
                            </Button>
                        </DropDown>
                    </>
                ) : (
                    <>
                        <NavLink className="fw-bold fs-6" to="/auth/signin">
                            Login
                        </NavLink>
                        <Button
                            variant="primary btn-sm book-appointment-btn px-2"
                            onClick={handleBookAppointment}
                        >
                            {appointmentButtonText}
                        </Button>
                    </>
                )}
            </div>
            <div
                className="fs-6 fw-bold text-primary d-block d-md-none cursor-pointer"
                onClick={() => setShowProfileDrawer(!showProfileDrawer)}
            >
                MY PROFILE
            </div>

            <OutsideAlerter onClickOutside={() => setIsDrawerOpen(false)}>
                <SideDrawer
                    direction="left"
                    isOpen={isDrawerOpen}
                    setIsOpen={setIsDrawerOpen}
                >
                    <div className="side-drawer-items text-center d-flex flex-column justify-content-start mx-auto">
                        <NavLink className="fw-bold fs-5" to="/">
                            Home
                        </NavLink>
                        <div className="d-flex justify-content-center align-items-center  mt-6">
                            <NavLink className="fw-bold fs-5" to="/">
                                Conditions
                            </NavLink>
                        </div>
                        <NavLink className="fw-bold fs-5 mt-6" to="/">
                            <nobr>About us</nobr>
                        </NavLink>
                        <NavLink className="fw-bold fs-5 mt-6" to="/">
                            FAQ
                        </NavLink>
                    </div>
                </SideDrawer>
            </OutsideAlerter>

            <OutsideAlerter onClickOutside={() => setShowProfileDrawer(false)}>
                <SideDrawer
                    direction="right"
                    isOpen={showProfileDrawer}
                    setIsOpen={setShowProfileDrawer}
                >
                    <Tooltip
                        message="There is already an active order in progress"
                        position="top"
                        hidden={canOrder}
                    >
                        <Button
                            variant="primary btn-sm book-appointment-btn fw-bold px-2"
                            onClick={handleBookAppointment}
                            disabled={!canOrder}
                        >
                            {appointmentButtonText}
                        </Button>
                    </Tooltip>

                    {auth.isLogged ? (
                        <Collapsible
                            title={profileData?.displayName}
                            className="btn border py-3 px-0 ps-2 mt-4 w-100"
                            textColor="text-primary body body-small"
                        >
                            <div className="pe-2">
                                <div className="mb-2">
                                    <Button
                                        variant="white py-3"
                                        onClick={handleRouteToProfile}
                                    >
                                        Profile
                                    </Button>
                                </div>
                                <Button
                                    variant="outline-primary py-3"
                                    onClick={handleSignout}
                                >
                                    Signout
                                </Button>
                            </div>
                        </Collapsible>
                    ) : (
                        <div className="mt-auto">
                            <Button
                                variant="outline-primary my-3"
                                onClick={handleRouteToLogin}
                            >
                                Login
                            </Button>
                        </div>
                    )}
                </SideDrawer>
            </OutsideAlerter>
        </nav>
    );
}
